//****************/
const ORG_KEY = "Organization_ID";
const COLL_KEY = "Collection_ID";

export function storeOrganizationId(orgId) {
    if (orgId) localStorage.setItem(ORG_KEY, orgId);
    else localStorage.removeItem(ORG_KEY);
}
export function readOrganizationId() {
    return localStorage.getItem(ORG_KEY);
}

export function storeCollectionId(collId) {
    if (collId) localStorage.setItem(COLL_KEY, collId);
    else localStorage.removeItem(COLL_KEY);
}
export function readCollectionId() {
    return localStorage.getItem(COLL_KEY);
}