import { configureStore } from '@reduxjs/toolkit';
import { emptyApi } from "./api/emptyApi";
import generalReducer, { generalSlice } from "./GeneralSlice";
import tasksReducer, { tasksSlice } from './TasksSlice';
import WFtasksReducer, { tasksSlice as WFTasksSlice } from './WFTasksSlice';
import { localApi } from './api/localApi';

const store = configureStore({
    reducer: {
        [generalSlice.name]: generalReducer,
        [tasksSlice.name]: tasksReducer,
        [WFTasksSlice.name]: WFtasksReducer,
        [emptyApi.reducerPath]: emptyApi.reducer,
        [localApi.reducerPath]: localApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(emptyApi.middleware)
            .concat(localApi.middleware),
});

export default store;