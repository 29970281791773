import { emptyApi as api } from "./emptyApi";
import { UserDetails, UserIdArgs } from "./users";

const organizationsRtkApi = api
    .enhanceEndpoints({ addTagTypes: ["organizations", "members", "budget", "budget-enabled"] })
    .injectEndpoints({
        endpoints: (build) => ({
            getAllOrganizations: build.query<OrganizationInfo[], undefined>({
                query: () => ({
                    url: `/organizations`
                }),
                providesTags: ["organizations"]
            }),
            addOrganization: build.mutation<any, AddOrganizationArgs>({
                query: (args) => ({
                    url: `/organizations`,
                    method: "POST",
                    body: args
                }),
                invalidatesTags: ["organizations"]
            }),
            getCurrentUserOrganizations: build.query<OrganizationInfoAdmin[], undefined>({
                query: () => ({
                    url: `/organizations/for-user/me`
                })
            }),
            getUserOrganizations: build.query<OrganizationInfoAdmin[], UserIdArgs>({
                query: (args) => ({
                    url: `/organizations/for-user/${args.user_uuid}`
                })
            }),
            getOrganization: build.query<OrganizationInfo, OrganizationIdArgs>({
                query: (args) => ({
                    url: `/organizations/${args.org_uuid}`
                })
            }),
            getOrganizationMembers: build.query<UserDetailsAdmin[], OrganizationIdArgs>({
                query: (args) => ({
                    url: `/organizations/${args.org_uuid}/users`
                }),
                providesTags: (result, error, args) => [{ type: "members", id: args.org_uuid }]
            }),
            createOrganizationMember: build.mutation<any, CreateOrganizationMemberArgs>({
                query: (args) => ({
                    url: `/organizations/${args.org_uuid}/users`,
                    method: "POST",
                    params: args.params
                }),
                invalidatesTags: (result, error, args) => [{ type: "members", id: args.org_uuid }]
            }),
            addOrganizationMember: build.mutation<any, AddOrganizationMemberArgs>({
                query: (args) => ({
                    url: `/organizations/${args.org_uuid}/users/${args.user_uuid}`,
                    method: "POST",
                    params: args.params
                }),
                invalidatesTags: (result, error, args) => [{ type: "members", id: args.org_uuid }]
            }),
            removeOrganizationMember: build.mutation<any, RemoveOrganizationMemberArgs>({
                query: (args) => ({
                    url: `/organizations/${args.org_uuid}/users/${args.user_uuid}`,
                    method: "DELETE"
                }),
                invalidatesTags: (result, error, args) => [{ type: "members", id: args.org_uuid }]
            }),
            getOrganizationBudgetSummary: build.query<BudgetSummary, OrganizationIdArgs>({
                query: (args) => {
                    return {
                        url: `organizations/${args.org_uuid}/budget-summary`
                    };
                },
                providesTags: (result, error, args) => [{ type: "budget", id: args.org_uuid }]
            }),
            getOrganizationBudget: build.query<BudgetInfo[], OrganizationIdArgs>({
                query: (args) => ({
                    url: `organizations/${args.org_uuid}/budget`
                }),
                providesTags: (result, error, args) => [{ type: "budget", id: args.org_uuid }]
            }),
            addOrganizationBudget: build.mutation<any, AddBudgetArgs>({
                query: (args) => ({
                    url: `organizations/${args.org_uuid}/budget`,
                    method: "POST",
                    body: args.body
                }),
                invalidatesTags: (result, error, args) => [{ type: "budget", id: args.org_uuid }]
            }),
            deleteOrganizationBudget: build.mutation<any, DeletetBudgetArgs>({
                query: (args) => ({
                    url: `organizations/${args.org_uuid}/budget/${args.budget_voice_uuid}`,
                    method: "DELETE"
                }),
                invalidatesTags: (result, error, args) => [{ type: "budget", id: args.org_uuid }]
            }),
            getOrganizationBudgetEnabled: build.query<any, OrganizationIdArgs>({
                query: (args) => ({
                    url: `organizations/${args.org_uuid}/budget-enabled`
                }),
                providesTags: (result, error, args) => [{ type: "budget-enabled", id: args.org_uuid }]
            }),
            setOrganizationBudgetEnabled: build.mutation<any, BudgetEnabledArgs>({
                query: (args) => ({
                    url: `organizations/${args.org_uuid}/budget-enabled`,
                    method: "PUT",
                    params: args.params
                }),
                invalidatesTags: (result, error, args) => [{ type: "budget-enabled", id: args.org_uuid }]
            }),
            getCurrentUserPrivateOrganization: build.query<any, undefined>({
                query: (args) => ({
                    url: `/organizations/private-user-orgs/me`
                })
            })
        }),
        overrideExisting: false
    });

export default organizationsRtkApi;

export type AddOrganizationArgs = {
    name: string;
    description: string;
    domain?: string;
};

export type OrganizationInfo = {
    uuid: string;
    name: string;
    description: string;
    domain: string;
    disabled: boolean;
    private: boolean;
};

export type OrganizationInfoAdmin = OrganizationInfo & {
    is_admin: boolean;
};

export type OrganizationIdArgs = {
    org_uuid: string;
};

export type CreateOrganizationMemberArgs = OrganizationIdArgs & {
    params: {
        user_email: string;
        make_admin: boolean;
    };
};

export type UserDetailsAdmin = UserDetails & {
    is_admin: boolean;
};

export type RemoveOrganizationMemberArgs = OrganizationIdArgs & UserIdArgs;

export type AddOrganizationMemberArgs = RemoveOrganizationMemberArgs & {
    params: {
        add_as_admin?: boolean;
    };
};

export type BudgetInfo = {
    validity_start: number;
    validity_end: number;
    resource: string;
    budget_used: number;
    budget_total: number;
    uuid?: string;
};

export type AddBudgetArgs = OrganizationIdArgs & {
    body: BudgetInfo;
};

export type DeletetBudgetArgs = OrganizationIdArgs & {
    budget_voice_uuid: string;
};

export type BudgetSummary = Record<
    string,
    {
        used: number;
        total: number;
        expires: number;
    }
>;

export type BudgetEnabledArgs = OrganizationIdArgs & {
    params: {
        enabled: boolean;
    };
};

export const {
    useGetAllOrganizationsQuery,
    useAddOrganizationMutation,
    useGetCurrentUserOrganizationsQuery,
    useLazyGetCurrentUserOrganizationsQuery,
    useGetUserOrganizationsQuery,
    useGetOrganizationQuery,
    useGetOrganizationMembersQuery,
    useAddOrganizationMemberMutation,
    useCreateOrganizationMemberMutation,
    useRemoveOrganizationMemberMutation,
    useGetOrganizationBudgetSummaryQuery,
    useGetOrganizationBudgetQuery,
    useAddOrganizationBudgetMutation,
    useDeleteOrganizationBudgetMutation,
    useGetOrganizationBudgetEnabledQuery,
    useSetOrganizationBudgetEnabledMutation,
    useGetCurrentUserPrivateOrganizationQuery
} = organizationsRtkApi;
